import { Routes, Route, useLocation } from "react-router-dom";
import './App.css';
import { Suspense, lazy } from "react";

function App() {
  const location = useLocation();
  const Home = lazy(() => import('./components/Home/Home'));
  const Products = lazy(() => import('./components/Products/Products'));
  const ProductCategory = lazy(() => import('./components/Products/ProductCategory/ProductCategory'));
  const Posts = lazy(() => import('./components/Posts/Posts'));
  const Post = lazy(() => import('./components/Posts/Post/Post'));
  const Gallery = lazy(() => import('./components/Gallery/Gallery'));
  const Story = lazy(() => import('./components/Story/Story'));
  const Contact = lazy(() => import('./components/Contact/Contact'));
  const Login = lazy(() => import('./components/Login/Login'));
  const Board = lazy(() => import('./components/Board/Board'));
  return (
    <Suspense>
      <Routes location={location} key={location.pathname}>

        <Route exact path="/login" element={<Login/>}/>
        <Route exact path="/board" element={<Board/>}/>

        <Route exact path="/" element={<Home/>}/>

        <Route exact path="/productos" element={<Products/>}/>
        <Route path="/productos/:id" element={<ProductCategory/>}/>

        <Route exact path="/galeria" element={<Gallery/>}/>

        <Route exact path="/noticias" element={<Posts/>}/>
        <Route path="/noticias/:id" element={<Post/>}/>

        <Route exact path="/historia" element={<Story/>}/>

        <Route exact path="/contacto" element={<Contact/>}/>
        
      </Routes>
      </Suspense>
  );
}

export default App;
